.App {
  text-align: center;
  overflow: hidden;
}
.Mui-selected path[fill="#303030"],
.Mui-selected path[fill="black"] {
  fill: #3858e9;
}
.Mui-selected path[stroke="#303030"] {
  stroke: #3858e9;
}
.action-button {
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.action-button:hover {
  background-color: #e7e7e7;
  border-radius: 50%;
}
div canvas {
  display: inline-block !important;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-left: 36px;
}
.form .MuiTextField-root {
  margin: 6px;
  width: 350px;
}

.custom-modal-title {
  text-align: left;
  padding-top: 10px;
  margin: 0;
  padding-left: 15px;
}

.custom-button {
  width: 100px;
}
.custom-margin-top {
  margin-top: 15px;
}
.custom-align-left {
  text-align: left;
  margin-left: 20px;
}
.custom-padding {
  padding: 20px;
}
.custom-display-flex {
  display: flex !important;
}
.pagination {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}
.action.MuiTableCell-root {
  display: flex;
  align-items: center;
}
.add {
  display: flex;
  justify-content: right;
}

.add-btn.MuiButtonBase-root {
  height: 45px;
  color: white;
  background-color: #0d6efd;
}
.add-btn.MuiButtonBase-root:hover {
  height: 45px;
  color: white;
  background-color: #0d6efd;
}
.btns-with-icon.MuiButtonBase-root {
  height: 30px;
  color: white;
  background-color: #0d6efd;
}
.btns-with-icon.MuiButtonBase-root:hover {
  height: 30px;
  color: white;
  background-color: #0d6efdc9;
}
.customTabs {
  border-width: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(208, 208, 208, 1);
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.search-box legend {
  width: 0 !important;
}

/* CSS for drawer form and it's component */

.drawer-form-wrapper {
  z-index: 5;
  position: fixed;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 40%;
  background-color: #fff;
  box-shadow: -1px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(235 235 235 / 1);
  transition: transform 400ms cubic-bezier(0, 0, 0.2, 1),
    visibility 400ms cubic-bezier(0, 0, 0.2, 1), width 500ms ease-in-out 0s;
}

.drawer-form-open {
  transform: none;
  visibility: visible;
}

.drawer-form-closed {
  transform: translateX(100%);
  visibility: hidden;
}

.drawer-form-container {
  text-align: left;
  margin: 0 40px;
}
.close-button {
  text-align: right;
  margin-right: 40px;
}

.drawer-form-container legend {
  width: 0 !important;
}

.form-input-label {
  margin-bottom: 8px;
}

.MuiInputLabel-asterisk {
  color: red;
}

.form-input {
  margin: 12px 0;
  width: 100%;
}

.placeholder-style {
  color: #b3b3b3;
  font-weight: 400;
}

/* Buttons */

.primary-button-outlined {
  color: rgba(0, 82, 204, 1) !important;
  border: 1px solid rgba(0, 82, 204, 1) !important;
}

.primary-button-filled {
  background-color: rgba(0, 82, 204, 1) !important ;
}
.primary-button-filled:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important ;
}

.btn-primary-main-add {
  font-size: 15px !important;
  padding: 15px 12px 15px 12px !important;
  height: 40px !important;
  background-color: rgba(0, 82, 204, 1) !important ;
  color: white !important;
  text-align: center;
}
.main-title-heading-container {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 20px !important;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -3px,
    rgba(0, 0, 0, 0.14) 0px 4px 6px 1px, rgba(0, 0, 0, 0.12) 0px 1px 7px 2px;
  border-radius: 5px;
}

.form-container {
  text-align: left;
  width: 400px;
}

.form-container legend {
  width: 0 !important;
}

.dialog-heading {
  font-size: medium;
  font-weight: 600;
  font-size: 17px;
}

.MuiAutocomplete-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}
.auth-heading {
  text-align: left;
  margin-left: 38px;
  font-weight: 600;
  color: #303030;
  font-size: 26px;
  margin-top: 10px;
}
.auth-subheading {
  text-align: left;
  margin-left: 40px;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500;
  font-size: 17px;
  padding-top: 6px;
}
.input-field.MuiFormControl-fullWidth {
  width: 541px;
  border-radius: 8px;
  right: 5px;
}
.submit-button.MuiButtonBase-root {
  width: 541px;
  background-color: #0052cc;
}
.delete-component.MuiPaper-elevation24 {
  width: 440px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.MuiDialogActions-root {
  padding: 0px 0px 0px 0px;
  margin-right: 25px;
  margin-bottom: 5px;
}
.dialog-content.MuiDialogContentText-root {
  font-size: 14px;
}
.delete-buttons.MuiDialogActions-root {
  padding-bottom: 25px;
}
.helper-text {
  color: gray;
  font-size: small;
}
.reporting.MuiButtonBase-root {
  border: 1px solid #0052cc;
  border-radius: 5px;
}
.input-list legend {
  width: 0;
}

.login-image {
  width: 70%;
}

.input-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}

.checkbox-list {
  overflow: auto;
}

.page-title {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.sub-heading {
  color: gray;
  font-size: 14px;
  text-align: start;
}
.btns-with-icon.MuiBox-root {
  display: flex;
  align-items: center;
  gap: 4px;
}
.btns-with-icon-text.MuiTypography-root {
  font-size: 15px;
}
.outer-container {
  display: flex;
  height: 100vh;
  overflow-x: hidden;
}
.login-container {
  height: 538px;
  background: rgba(240, 242, 242, 0.45);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 99px;
  margin-left: 80px;
  width: 740px;
  border-radius: 10px;
}
.auth-form {
  padding-top: 145px;
  flex: 1;
}
.logo {
  height: 40px;
  max-width: "100%";
  float: left;
  padding-left: 40px;
}
@media only screen and (max-width: 600px) {
  .outer-container {
    flex-direction: column;
  }
  .outer-container img {
    max-width: 100%;
  }
}
.responsive-image {
  max-width: 100%;
  height: 40px;
}

@media only screen and (max-width: 1024px) {
  .logo-login {
    padding-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .auth-heading {
    margin-left: 52px;
    font-size: 26px;
  }
}
@media only screen and (max-width: 600px) {
  .auth-subheading {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 700px) {
  .login-container {
    margin-top: 60px;
    margin-left: 40px;
    width: 90%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 480px) {
  .login-container {
    margin-top: 40px;
    margin-left: 20px;
    width: 100%;
  }
  .submit-button.MuiButtonBase-root {
    width: 305px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 370px) {
  .login-container {
    margin-top: 40px;
    margin-left: 20px;
    width: 100%;
  }
  .submit-button.MuiButtonBase-root {
    width: 255px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .login-container {
    margin-top: 40px;
    margin-left: 20px;
    width: 45%;
  }
  .login-image {
    width: 270px;
  }
  .submit-button.MuiButtonBase-root {
    width: 300px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  .login-container {
    margin-top: 40px;
    margin-left: 20px;
    width: 45%;
  }
  .login-image {
    width: 270px;
  }
  .submit-button.MuiButtonBase-root {
    width: 540px;
  }
}
@media only screen and (max-width: 600px) {
  .auth-form {
    padding-top: 35px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 370px) {
  .input-field.MuiFormControl-fullWidth {
    width: 250px;
    border-radius: 8px;
  }
  .form {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 400px) {
  .input-field.MuiFormControl-fullWidth {
    width: 300px;
    border-radius: 8px;
  }
  .form {
    margin-left: 42px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 760px) {
  .input-field.MuiFormControl-fullWidth {
    width: 320px;
    border-radius: 8px;
  }
  .form {
    margin-left: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .input-field.MuiFormControl-fullWidth {
    width: 300px;
    border-radius: 8px;
  }
  .form {
    margin-left: 44px;
  }
}
@media only screen and (max-width: 370px) {
  .submit-button.MuiButtonBase-root {
    margin-right: 20px;
  }
}
.checkbox-list {
  overflow-y: auto;
}
.input-list {
  display: flex;
}
.error-text {
  color: #d32f2f;
  margin-bottom: 2px;
}

.MuiChip-root {
  font-family: "DM Sans" !important;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  color: #7c7c7c;
}

.MuiTablePagination-root p {
  margin-top: revert;
}

.date-time-filter {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  width: 600px;
  justify-content: space-between;
}

.date-time-picker input {
  padding: 8.5px 12px;
  width: 170px;
}
.date-time-picker label[data-shrink="false"] {
  -webkit-transform: translate(14px, 9px) scale(1);
  -moz-transform: translate(14px, 9px) scale(1);
  -ms-transform: translate(14px, 9px) scale(1);
  transform: translate(14px, 9px) scale(1);
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form .MuiTextField-root {
  margin: 6px;
  width: 400px;
}

.signup-button {
  background-color: #0052cc;
  width: 400px;
}

.warning-text {
  color: #d32f2f;
  font-size: small;
}

.selected-list-wrapper {
  overflow: auto;
  scrollbar-gutter: stable;
}
